<template>
  <div ref="videoMain" :class="['flv-player-wrapper',full?'full':'']">
    <div v-loading="isLoad" class="drag-box" element-loading-text="视频加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <span v-if="isAI &&!source" class="error-message">正在等待AI识别模块加载!</span>
      <span v-if="message" class="error-message">视频加载失败!</span>
      <!-- <div class="target-match" ref="matchedTarget" v-if="isTrace && traceCoor.length == 2" :style="targetMatchStyle"></div> -->
      <video id="player" ref="RtsPlayerEl" :class="className" muted style="width: 100%; height: 100%; object-fit: fill" />
      <div class="ctrl-bar-wrapper">
        <div class="left-time-area-wrapper" />
        <div class="right-ctrl-area-wrapper">
          <el-tooltip class="item" effect="dark" content="刷新" placement="top-start">
            <span class="ctrl-item renovate" @click="refresh(className)"> 
              <i class="el-icon-refresh-left" />
            </span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="全屏" placement="top-start">
            <span class="ctrl-item fullscreen" @click="screenrc">
              <i class="el-icon-full-screen" />
            </span>
          </el-tooltip>
           <el-tooltip class="item" effect="dark" content="在新窗口中打开" placement="top-start">
            <span class="ctrl-item renovate" @click="openWindow()"> 
              <i class="el-icon-s-promotion" />
            </span>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FLV from 'flv.js';
import AliRts from '@/utils/RTS.js';
 const PLAY_EVENT = {
        CANPLAY: "canplay",
        WAITING: "waiting",
        PLAYING: "playing"
      }
export default {
  name: 'FlvPlayer',
  props: {

    full: {
      type: Boolean,
      default: false
    },
    vlabel: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    rtcUrl: {
      type: String,
      default: ''
    },

    className: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 0
    },
    controlAble: {
      type: Boolean,
      default: false
    },
    isARtc: {
      type: Boolean,
      default: false
    },
    isAI: {
      type: Boolean,
      default: false
    },
    traceCoor: {
      type: Array,
      default() {
        return []
      },
    },

    isTrace: {
      type: Boolean,
      default: false,
    },
    isBoxing: {
      type: Boolean,
      default: false
    },
    isPoint: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      message: false,
      isLoad: false,
      aliRtsPlayer: null,
      container: null,
      playerBaseClient: null,
      aspect: '16:9',
      PLAY_EVENT:'',
      rPlayerTimer:null,
      fluent: true,
      autoplay: true,
      pointW: 0,
      pointH: 0
    };
  },

  watch: {
    source(newSrc, oldSrc) {
      console.log(newSrc)
      this.destroyPlayerBaseClient();
      if (newSrc) {
        if (this.isARtc) {
          this.initAliRtcPlayer(this.rtcUrl);
        } else {
          this.initializePlayer(this.source);
        }
      }
    },
    rtcUrl(newSrc, oldSrc) {
      console.log('new rtvurl:' + newSrc);
      this.destroyPlayerBaseClient();
      if (newSrc) {
        if (this.isARtc) {
          this.initAliRtcPlayer(this.rtcUrl);
        } else {
          this.initializePlayer(this.source);
        }
      }
    },


    isBoxing(v) {
      if (!this.controlAble) {
        return;
      }
      if (v) {
        this.activeBoxing();
      } else {
        this.stopBoxing();
      }
    },

  },
  computed: {
    targetMatchStyle() {
      let topLeft = this.traceCoor[0],
        bottomRight = this.traceCoor[1];
      let width = parseInt((bottomRight[0] - topLeft[0]) * 100) + '%',
        height = parseInt((bottomRight[1] - topLeft[1]) * 100) + '%',
        left = parseInt(topLeft[0] * 100) + '%',
        top = parseInt(topLeft[1] * 100) + '%';
      return `width:${width};height:${height};top:${top};left:${left};`;
    },
  },
  mounted() {
    console.log(this.isARtc)
     console.log(this.rtcUrl)
      console.log(this.source)
      
    if (this.controlAble && this.isBoxing) {
      this.activeBoxing();
    }
    this.container = document.querySelector(`.${this.className}`);
    this.container.addEventListener('playing', () => (this.isLoad = false));
    if (!this.isAI) {
      if (this.isARtc) {
        this.initAliRtcPlayer(this.rtcUrl);
      } else {
        this.initializePlayer(this.source);
      }
    }


    // // 测试追踪
    // this.setRecognitionBox([0.1, 0.1], [0.8, 0.8])

    // // 测试取消追踪
    // setTimeout(() => {
    //   this.setRecognitionBox()
    // }, 5000)
  },

  beforeDestroy() {
    this.destroyPlayerBaseClient();
  },
 
  methods: {

    openWindow(){
      let url =  window.location.href.split('#')[0];
      let source = this.isARtc?this.rtcUrl:this.source;

      let openSrc = url+'#/webrtcView?source='+source+'&isARtc='+(this.isARtc?1:0)
      console.log(openSrc)
      debugger
     let opner =  window.open(((openSrc)))
    },
    // 开启框选
    activeBoxing() {
      this.stopBoxing();
      const wrap = document.querySelector('.flv-player-wrapper');
      const mouseArea = document.createElement('div');
      mouseArea.setAttribute('class', 'mouse-wrap');
      wrap.appendChild(mouseArea);
      const _self = this;
      mouseArea.onmousedown = function (event) {
        if (event.button != 0) {
          return
        }
        event.stopPropagation();
        const curtain = document.createElement('div');
        _self.pointW = event.offsetX;
        _self.pointH = event.offsetY;
        curtain.setAttribute('class', 'curtain-wrap');
        curtain.style.position = 'absolute';
        curtain.style.top = `${_self.pointH}px`;
        curtain.style.left = `${_self.pointW}px`;
        console.error(_self.isBoxing)
        if (!_self.isBoxing) {
          curtain.style.opacity = 0;
        } else {
          curtain.style.opacity = 1;
        }
        wrap.appendChild(curtain);

        mouseArea.onmousemove = function msmove(event) {
          event.stopPropagation();
          const el = document.getElementsByClassName('curtain-wrap');
          if (el.length > 1) {
            for (let i = 1; i < el.length; i++) {
              el[i].remove();
            }
          }
          if (el[0]) {
            el[0].style.width = `${event.offsetX - _self.pointW}px`;
            el[0].style.height = `${event.offsetY - _self.pointH}px`;
          }
        };
      };
      mouseArea.onmouseup = (event) => {
        if (event.button != 0) {
          return
        }
        console.error(event)
        event.stopPropagation();
        const el = document.getElementsByClassName('curtain-wrap');
        console.error(el)
        if (el) {
          const elem = document.querySelector('.flv-player-wrapper');
          const topLeft = [parseFloat((_self.pointW / elem.offsetWidth).toFixed(2)), parseFloat((_self.pointH / elem.offsetHeight).toFixed(2))];
          const bottomRight = [parseFloat((event.offsetX / elem.offsetWidth).toFixed(2)), parseFloat((event.offsetY / elem.offsetHeight).toFixed(2))];
          const topLeftBottomRight = [topLeft, bottomRight];
          if (this.isPoint && this.isPointCoor(topLeft, bottomRight)) {
            this.$emit('cameraAuto', topLeft);
          } else if (this.isBoxing) {
            this.$emit('submitParams', topLeftBottomRight);
          }
          for (let i = 0; i < el.length; i++) {
            el[i].remove();
          }

        }
      };
    },
    stopBoxing() {
      const targetDom = document.querySelector('.mouse-wrap');
      const curtainDom = document.querySelector('.curtain-wrap');
      if (targetDom) {
        targetDom.onmousedown = null;
        targetDom.onmouseup = null;
        targetDom.onmousemove = null;
        targetDom.remove();
      }
      if (curtainDom) {
        curtainDom.remove();
      }
    },

    isPointCoor(arr1, arr2) {
      if (arr1[0] === arr2[0] && arr1[1] === arr2[1]) {
        return true;
      }
      return false;

    },

    // 设置视频中识别的物体,coor1,coor2 分别为左上角，右下角
    setRecognitionBox(coor1, coor2) {
      let showRectangle = document.querySelector('.video-target');
      if (arguments.length !== 2) {
        // 清除框
        if (showRectangle) {
          showRectangle.remove();
        }
      } else {
        const { videoMain } = this.$refs;
        if (!showRectangle) {
          showRectangle = document.createElement('div');
          showRectangle.setAttribute('class', 'video-target');
          showRectangle.setAttribute('style', '');
          videoMain.appendChild(showRectangle);
        }
        showRectangle.style.width = `${(coor2[0] - coor1[0]) * 100}%`;
        showRectangle.style.height = `${(coor2[1] - coor1[1]) * 100}%`;
        showRectangle.style.left = `${coor1[0] * 100}%`;
        showRectangle.style.top = `${coor1[1] * 100}%`;
      }
    },

    async initializePlayer(url) {
      console.log(url);
      const { isSupported, createPlayer, Events } = FLV;
      const compatibility = isSupported();
      if (compatibility) {
        this.playerBaseClient = await this.createFLVPlayer(createPlayer, url);
        this.playerBaseClient.attachMediaElement(this.container);
        this.playerBaseClient.load();
        this.playerBaseClient.play();
      } else {
        this.$message.error('该浏览器不支持此视频播放器');
      }
      /* 监听播放器错误，如果出现播放错误则重置播放器 */
      this.playerBaseClient.on(Events.ERROR, (errorType, errorDetail, errorInfo) => {
         console.log(errorType, errorDetail, errorInfo)
        if (this.container) {
          this.destroyPlayerBaseClient();
          createPlayer(this.mediaDataSource, this.optionalConfig);
        }
      });
      /* 监听画面卡死 */
      this.playerBaseClient.on('statistics_info', function (e) {
        console.log(e)
        if (this.lastDecodedFrame == 0) {
          this.lastDecodedFrame = e.decodedFrames;
          return;
        }
        if (this.lastDecodedFrame != e.decodedFrames) {
          this.lastDecodedFrame = e.decodedFrames;
        } else {
          this.lastDecodedFrame = 0;
        }
      });
      if (this.timerId !== null) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        if (this.container.buffered.length > 0) {
          const end = this.playerBaseClient.buffered.end(0); // 视频结尾时间
          const current = this.playerBaseClient.currentTime; // 视频当前时间
          const diff = end - current; // 相差时间
          const diffCritical = 4; // 这里设定了超过4秒以上就进行跳转
          const diffSpeedUp = 1; // 这里设置了超过1秒以上则进行视频加速播放
          const maxPlaybackRate = 4; // 自定义设置允许的最大播放速度
          let playbackRate = 1.0; // 播放速度
          if (diff > diffCritical) {
            this.playerBaseClient.currentTime = end - 1.5;
            playbackRate = Math.max(1, Math.min(diffCritical, 16));
          } else if (diff > diffSpeedUp) {
            playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16));
          }
          this.playerBaseClient.playbackRate = playbackRate;
          if (this.playerBaseClient.paused) this.playerBaseClient.play();
        }
      }, 1000);
    },
    createFLVPlayer(useCallback, url) {
      return new Promise((resolve, reject) => {
        const player = useCallback(
          {
            type: 'flv',
            hasAudio: false,
            isLive: true,
            url // 'http://10.0.221.210/0.flv' //'http://10.127.20.97:10800/flv/hls/stream_3.flv'
          },
          {
            cors: false, // 是否跨域
            enableWorker: true, // 是否多线程工作
            enableStashBuffer: false, // 是否启用缓存
            stashInitialSize: 8192, // 缓存大小(kb)  默认384kb
            autoCleanupSourceBuffer: true, // 是否自动清理缓存
            enableWorker: false, // 不启用分离线程
            reuseRedirectedURL: true // 重用301/302重定向url，用于随后的请求，如查找、重新连接等。
          }
        );
        resolve(player);
      });
    },
    destroyPlayerBaseClient() {
      if (this.playerBaseClient) {
        this.playerBaseClient.pause();
        this.playerBaseClient.unload();
        this.playerBaseClient.detachMediaElement();
        this.playerBaseClient.destroy();
        this.playerBaseClient = null;
      }
      if (this.aliRtsPlayer) {
        this.stopPullData();
        this.aliRtsPlayer = null;
      }
    },

    // 阿里RTC
    initAliRtcPlayer(url) {
      console.log(url);
      this.message = false;
      this.isLoad = true;
      new Promise((resolve, reject) => {
        const play = this.aliRtsPlayer ? this.aliRtsPlayer : new AliRTS.createClient();
        resolve(play);
      }).then((player) => {
        this.aliRtsPlayer = player;
       this.aliRtsPlayer.on('onPlayEvent', (play) => {
     //   console.error(JSON.stringify(play))
        this.PLAY_EVENT = play.event
        if (play.event === PLAY_EVENT.CANPLAY) {
          //拉流可以播放
          console.log('CANPLAY')
          this.rPlayerTimer && clearInterval(this.rPlayerTimer)
          this.rPlayerTimer = null
        } else if (play.event === PLAY_EVENT.WAITING) {
          this.isLoad = true
        if(!this.rPlayerTimer){
           this.rPlayerTimer = setInterval(() => {
            console.error('refresh')
            this.refresh(this.className)
          }, 3000)
        } 
        } else if (play.event === PLAY_EVENT.PLAYING) {
          this.isLoad = false
           this.rPlayerTimer && clearInterval(this.rPlayerTimer)
          this.rPlayerTimer = null
        }else  { //if (play.event === PLAY_EVENT.PLAYING)
          this.rPlayerTimer && clearInterval(this.rPlayerTimer)
          this.rPlayerTimer = null
        }
      })
      this.aliRtsPlayer.on('onError', (error) => {
        console.log('isError',error)
      })
        player.subscribe(url).then((remoteStream) => {
            remoteStream.play(this.$refs.RtsPlayerEl);
          }).catch((error) => {
            console.error(error);
            this.message = true;
            this.isLoad = false;
          });
      });
    },

    stopPullData() {
      this.aliRtsPlayer && this.aliRtsPlayer.unsubscribe();
    },

    refresh() {
      this.destroyPlayerBaseClient();
      this.initializePlayer(this.source);
    },
    screenrc() {
      this.$refs.RtsPlayerEl.webkitRequestFullscreen();
    }
  }
};
</script>

<style lang="scss">
.right-ctrl-area-wrapper{
  i{
    font-size: 18px;
  }
}
.curtain-wrap {
  border: 2px dashed #2ceaff;
  z-index: 10;
}
.video-target {
  position: absolute;
  border: 1px solid #1afa29;
}
.mouse-wrap {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
}

.full{
  width: 100% !important;;
  height: 100% !important;
}

.flv-player-wrapper {
  width: 512px;
  height: 288px;
  font-size: 14px;
  color: #eee;
  font-family: '黑体';
  position: relative;
  background-color: #333;
  z-index: 99;

  .drag-box {
    width: 100%;
    height: 100%;
    position: relative;
    .ctrl-bar-wrapper {
      height: 40px;
      box-sizing: border-box;
      position: absolute;
      right: 10px;
      bottom: 0;
      z-index: 999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .ctrl-item {
        margin: 0 5px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .fullscreen {
        margin: 0 10px 0 10px;
      }
    }
  }
  #player {
    width: 512px;
    height: 288px;
    background-color: #333;
  }

  .move-btn {
    width: 16px;
    height: 16px;
    background-color: green;
    position: absolute;
    border-radius: 50%;
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
  }
  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    color: #fff;
  }
}

.target-match {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
